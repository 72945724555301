<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="reviews"
              :server-items-length="totalReviews"
              :options.sync="options"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': pageSizes
              }"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-icon left>mdi-comment-text-multiple</v-icon>
                    App Feedback
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-if="showSearch"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @input="fetchReviews"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.userid="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      outlined
                      class="user-chip"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar v-if="item.firebase_user?.photourl" left>
                        <v-img :src="item.firebase_user.photourl"></v-img>
                      </v-avatar>
                      <v-avatar v-else left>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ item.firebase_user?.displayname || 'Unknown User' }}
                    </v-chip>
                  </template>
                  <span v-if="item.firebase_user">
                    Email: {{ item.firebase_user.email }}<br>
                    User ID: {{ item.firebase_user.uid }}
                  </span>
                  <span v-else>
                    No user details available
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.appname="{ item }">
                {{ item.appname }}
              </template>
              <template v-slot:item.appversion="{ item }">
                {{ item.appversion }}
              </template>
              <template v-slot:item.device="{ item }">
                {{ item.device }}
              </template>
              <template v-slot:item.category="{ item }">
                {{ item.category }}
              </template>
              <template v-slot:item.type="{ item }">
                {{ item.type }}
              </template>
              <template v-slot:item.feedback="{ item }">
                {{ item.feedback }}
              </template>
              <template v-slot:item.images="{ item }">
                <v-img
                  v-for="image in item.images"
                  :key="image.feedbackimageid"
                  :src="image.image"
                  max-width="100"
                  class="mr-2"
                ></v-img>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'FeedbackReviews',
  props: {
    maxRecordsToShow: {
      type: Number,
      default: 5
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'User', value: 'userid', width: '10%' },
        { text: 'App Name', value: 'appname', width: '10%' },
        { text: 'App Version', value: 'appversion', width: '10%' },
        { text: 'Device', value: 'device', width: '10%' },
        { text: 'Category', value: 'category', width: '10%' },
        { text: 'Feedback', value: 'feedback', width: '10%' },
        { text: 'Images', value: 'images', width: '10%' }
      ],
      options: {
        page: 1,
        itemsPerPage: this.maxRecordsToShow,
        sortDesc: [false],
        sortBy: ['-feedbackid']
      },
      pageSizes: [5, 10, 20, 30, 40, 50, 100, -1],
      totalReviews: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters('feedbackReviews', ['getAllReviews']),
    reviews() {
      return this.getAllReviews
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchReviews()
      },
      deep: true
    }
  },
  created() {
    this.fetchReviews()
  },
  methods: {
    ...mapActions('feedbackReviews', ['fetchReviews']),
    fetchReviews() {
      this.loading = true
      const params = {
        search: this.search,
        page: this.options.page,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage,
        ordering: this.options.sortBy.length ? (this.options.sortDesc[0] ? '-' : '') + this.options.sortBy[0] : '',
        comments_isnull: !this.showWithComments
      }

      this.$store.dispatch('feedbackReviews/fetchReviews', params).then((response) => {
        this.totalReviews = response.count
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.user-chip {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
