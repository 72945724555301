<template>
  <div>
    <DocumentReviews v-if="isSuperUser"/>
    <FeedbackReviews v-if="isSuperUser"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DocumentReviews from '@/components/documents/DocumentReviews.vue'
import FeedbackReviews from '@/components/FeedbackReviews.vue'

export default {
  name: 'DashboardPage',
  components: {
    DocumentReviews,
    FeedbackReviews
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('languages', ['languages']),
    ...mapState('account', ['user', 'groups', 'isSuperUser']),
    ...mapState('documents', ['documentTypes']),
    name() {
      if (!this.user) {
        return null
      }

      const fname = this.user.first_name ? this.user.first_name : null
      const lname = this.user.last_name ? this.user.last_name : null

      if (fname && lname) {
        return `${fname} ${lname}`
      }

      if (fname) {
        return fname
      }

      return 'Bhagatji'
    }
  }
}
</script>
