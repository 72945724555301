<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="reviews"
              :server-items-length="totalReviews"
              :options.sync="options"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': pageSizes
              }"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-icon left>mdi-book-open-page-variant</v-icon>
                    Reading Plan Reviews
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="showWithComments"
                    label="Comments"
                    @change="fetchReviews"
                  ></v-switch>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-if="showSearch"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    @input="fetchReviews"
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:item.documentid="{ item }">
                <router-link :to="{ name: 'document_home', params: { documentid: item.documentid } }">
                  {{ item.documentid_englishtitle }}
                </router-link>
              </template>
              <template v-slot:item.userid="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      outlined
                      class="user-chip"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar v-if="item.firebase_user?.photourl" left>
                        <v-img :src="item.firebase_user.photourl"></v-img>
                      </v-avatar>
                      <v-avatar v-else left>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ item.firebase_user?.displayname || 'Unknown User' }}
                    </v-chip>
                  </template>
                  <span v-if="item.firebase_user">
                    Email: {{ item.firebase_user.email }}<br>
                    User ID: {{ item.firebase_user.uid }}
                  </span>
                  <span v-else>
                    No user details available
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.rating="{ item }">
                <span v-if="showStars">
                  <v-rating
                    v-model="item.rating"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                </span>
                <span v-else>
                  {{ item.rating }} / 5
                </span>
              </template>
              <template v-slot:item.completedon="{ item }">
                {{ item.completedon }}
              </template>
              <template v-slot:item.comments="{ item }">
                {{ item.comments }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentReviews',
  props: {
    showStars: {
      type: Boolean,
      default: true
    },
    maxRecordsToShow: {
      type: Number,
      default: 5
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: '',
      showWithComments: true,
      headers: [
        { text: 'Document ID', value: 'documentid', width: '20%' },
        { text: 'User', value: 'userid', width: '20%' },
        { text: 'Rating', value: 'rating', width: '15%' },
        { text: 'Completed On', value: 'completedon', width: '20%' },
        { text: 'Comments', value: 'comments', width: '25%' }
      ],
      options: {
        page: 1,
        itemsPerPage: this.maxRecordsToShow,
        sortDesc: [false],
        sortBy: ['-completedon']
      },
      pageSizes: [5, 10, 20, 30, 40, 50, 100, -1],
      totalReviews: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters('readingPlanReviews', ['getAllReviews']),
    reviews() {
      return this.getAllReviews
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchReviews()
      },
      deep: true
    }
  },
  created() {
    this.fetchReviews()
  },
  methods: {
    ...mapActions('readingPlanReviews', ['fetchReviews']),
    fetchReviews() {
      this.loading = true
      const params = {
        search: this.search,
        page: this.options.page,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage,
        ordering: this.options.sortBy.length ? (this.options.sortDesc[0] ? '-' : '') + this.options.sortBy[0] : '',
        comments_isnull: !this.showWithComments
      }

      this.$store.dispatch('readingPlanReviews/fetchReviews', params).then((response) => {
        this.totalReviews = response.count
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.user-chip {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
